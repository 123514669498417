import React, {
  forwardRef,
  Ref,
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from "react"
import {
  CustomerCtx,
  useActions,
  CartCtx,
  AppCtx,
  useContextState,
} from "@components/contexted"
import {
  wrapper,
  wrapper__leaves,
  background,
  quote,
  orders,
  orders__item__buttons__quantity,
  orders__item,
  orders__item__active,
  orders__item__cart,
  orders__item__product,
  orders__item__label,
  orders__item__price,
  orders__chosen__price,
  orders__item__buttons,
  orders__item__buttons__btn,
  circle,
} from "./style/capacities.module.scss"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
import Left from "./icons/Animated/Left"
import Right from "./icons/Animated/Right"
import Bottle from "./images/bottle1.png"
import Cart from "./icons/cart.svg"
import Pajacyk from "./icons/pajacyk.svg"
import LeavesSVG from "./icons/leaves.svg"

const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const Capacities: React.ForwardRefRenderFunction<
  { products: any; lang: string },
  Ref<HTMLElement>
> = (props, ref): JSX.Element => {
  const { dispatch } = useContext(CartCtx)
  const [selected, setSelected] = useState<number | null>(null)
  const [state, setState] = useState({
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variation_id: null,
    variant: null,
    sale: false,
    price: "",
    regularPrice: "",
    unit: "PLN",
    name: "",
    slug: "",
    image: null,
  })

  const { products, lang } = props
  const bottleProducts = products.filter((el) => el.productId !== 347)
  const langBottleProducts = bottleProducts.filter(
    (el) => el.languages.code === lang
  )
  const reversedProducts = [].concat(langBottleProducts).reverse()
  const addToCart = useCallback((product) => {
    setState({
      product_id: product.productId,
      name: product.name,
      slug: product.slug,
      price: cleanPrice(product.regularPrice),
      regularPrice: cleanPrice(product.regularPrice),
      regularValue: cleanPrice(product.regularPrice),
      value: cleanPrice(product.regularPrice),
      quantity: 1,
      image: product.image,
    })
  }, [])

  const changeQty = useCallback(
    (val) => {
      const _number = Number(val)
      if (_number <= 0 || Number.isNaN(_number)) {
        return false
      } else {
        setState({
          ...state,
          quantity: _number,
          value: _number * state.price,
          regularValue: _number * state.regularPrice,
        })
      }
    },
    [selected]
  )
  const clearAndAddNew = (product) => {
    dispatch({
      type: "resetCart",
    })
    dispatch({
      type: "addToCart",
      payload: product,
    })
  }

  return (
    <>
      <section className={wrapper}>
        <h3>{translation[lang].title}</h3>
        <p>{translation[lang].subtitle}</p>
        <div className={wrapper__leaves}>
          <img src={LeavesSVG} />
        </div>
      </section>
      <div className={circle} />
      <AnimatedSection className={main__wrapper}>
        <section className={orders}>
          {reversedProducts.map((order, index) => (
            <div
              key={index}
              className={
                selected === index ? orders__item__active : orders__item
              }
              onClick={() => {
                if (selected !== index) {
                  setSelected(index)
                  addToCart(order)
                }
              }}
              tabIndex={0}
            >
              <img src={Bottle} alt="" className={orders__item__product} />
              <h4>{order.name}</h4>

              <div
                className={
                  selected === index
                    ? orders__chosen__price
                    : orders__item__price
                }
              >
                {selected === index
                  ? state.value
                  : cleanPrice(order.regularPrice)}
                zł
              </div>
              <label htmlFor="quantity" className={orders__item__label}>
                {translation[lang].quantity}
              </label>
              <div className={orders__item__buttons}>
                <AnimatedButton
                  className={orders__item__buttons__btn}
                  onClick={() => {
                    if (selected === index) {
                      if (state.quantity <= 1) {
                        return false
                      } else {
                        changeQty(state.quantity - 1)
                      }
                    }
                  }}
                >
                  <Left />
                </AnimatedButton>
                <div className={orders__item__buttons__quantity}>
                  {selected === index ? state.quantity : 1}
                </div>
                <AnimatedButton
                  className={orders__item__buttons__btn}
                  onClick={() => {
                    if (selected === index) changeQty(state.quantity + 1)
                  }}
                >
                  <Right />
                </AnimatedButton>
              </div>
              <AnimatedButton
                className={orders__item__cart}
                onClick={() => {
                  clearAndAddNew(state), ref.current.toggle()
                }}
              >
                <img src={Cart} alt="" />
                {translation[lang].cart}
              </AnimatedButton>
            </div>
          ))}
        </section>
      </AnimatedSection>
      <AnimatedSection className={quote}>
        <img src={Pajacyk} alt="Pajacyk" />
        <p>{translation[lang].second_subtitle}</p>
      </AnimatedSection>
    </>
  )
}

export default forwardRef(Capacities)

const translation = {
  pl: {
    title: "Różne pojemności dla Ciebie i twojej rodziny...",
    subtitle:
      "Teraz pełna moc oljeków CBD pełne spektru w większych opakowaniach 15ml, 30ml i 50 ml. Abyś mógł cieszyć się zdrowiem jak najdłużej.",
    quantity: "Podaj ilość",
    cart: "Dodaj do koszyka",
    second_subtitle:
      "Zakupem opakowania rodzinnego wspierasz Polską Akcję Humanitarną Pajacyk, 10% ceny przekażemy na ten cel.",
  },
  en: {
    title: "Different capacities for you and your family...",
    subtitle:
      "Now the full power of CBD full spectrum olives in larger packs of 15ml, 30ml and 50ml. So that you can enjoy your health for as long as possible.",
    quantity: "Specify quantity",
    cart: "Add to cart",
    second_subtitle:
      "By purchasing the family pack you support the Polish charity Pajacyk, 10% of the price will be donated to this cause.",
  },
}
