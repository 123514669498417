import React, { forwardRef } from "react"
import {
  container,
  container__button,
  container__wrapper,
  container__wrapper__item,
} from "./style/check.module.scss"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
import GuaranteeIcon from "./icons/Animated/GuaranteeIcon"
import MicroIcon from "./icons/Animated/MicroIcon"
import ProtectionIcon from "./icons/Animated/ProtectionIcon"

const getProps = () => {
  return {
    fromColor: "#FFFFFF",
    color: "#0E3019",
  }
}

const Check: React.FC = (props, ref): JSX.Element => {
  const { language } = props
  return (
    <>
      <AnimatedSection className={main__wrapper}>
        <section className={container} id="check">
          <h3>{translation[language].title}</h3>
          <p>{translation[language].subtitle}</p>
          <div className={container__wrapper}>
            <div className={container__wrapper__item}>
              <MicroIcon {...getProps()} />
              <p>{translation[language].micro}</p>
            </div>
            <div className={container__wrapper__item}>
              <GuaranteeIcon {...getProps()} />
              <p>{translation[language].guarantee}</p>
            </div>
            <div className={container__wrapper__item}>
              <ProtectionIcon {...getProps()} />
              <p>{translation[language].safe}</p>
            </div>
          </div>

          <AnimatedButton
            className={container__button}
            onClick={() => ref.current.toggle()}
          >
            {translation[language].button}
          </AnimatedButton>
        </section>
      </AnimatedSection>
    </>
  )
}

export default forwardRef(Check)

const translation = {
  pl: {
    title: "Sprawdź jak wyciskamy to co najlepsze z lubelskich konopi",
    subtitle:
      "Możesz sprawdzić badania ostatniej partii oleju, aby dowiedzieć się o naszych olejach premium typu full spectrum.",
    micro: "Potwierdzone badaniami",
    guarantee: "100% najlepsza jakość",
    safe: "Bezpieczny produkt",
    button: "Sprawdź aktualne badania",
  },
  en: {
    title: "Check out how we squeeze the best from the Lublin hemp",
    subtitle:
      "You can check out the research on the last batch of oil to learn about our premium full spectrum oils.",
    micro: "Confirmed by tests",
    guarantee: "100% best quality",
    safe: "Safe product",
    button: "Check current research",
  },
}
