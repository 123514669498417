import { useIntersectionObserver } from "@components/logic"
import React, { useMemo } from "react"
import { animated, useSpring } from "react-spring"
import useSVGOffset from "../../common/useSVGOffset"
import config, { getAnimatedSvgProps, IAnimatedIcon, IIcon } from "./config"

const Books: React.FC<IIcon> = ({ color, fromColor }) => {
  const { offset, pathRef } = useSVGOffset()
  const [{ inView }, marktoObserve] = useIntersectionObserver()
  return (
    <svg
      viewBox="0 -77 511.99906 511"
      xmlns="http://www.w3.org/2000/svg"
      ref={marktoObserve}
    >
      {typeof offset === "number" && inView ? (
        <AnimatedBooksIcon
          offset={offset}
          color={color}
          fromColor={fromColor}
        />
      ) : (
        <path
          ref={pathRef}
          d="m402.871094 357.023438c1.546875 0 3.121094-.371094 4.507812-1.0625l99.007813-49.269532c5.003906-2.488281 7.039062-8.566406 4.550781-13.570312l-142.84375-287.035156c-2.457031-4.9375-8.628906-7.007813-13.570312-4.550782l-99.003907 49.269532c-5.003906 2.492187-7.042969 8.566406-4.550781 13.570312l23.121094 46.46875c.003906 0 .003906.003906.003906.003906l15.199219 30.539063h-73.402344v-63.535157c0-5.589843-4.53125-10.121093-10.121094-10.121093h-74.9375v-41.789063c0-5.589844-4.535156-10.121094-10.121093-10.121094h-110.589844c-5.589844 0-10.121094 4.53125-10.121094 10.121094v320.613282c0 5.589843 4.53125 10.121093 10.121094 10.121093zm-16.339844-20.242188h-70.84375v-142.355469zm-1.605469-48.652344-63.550781-127.703125 80.886719-40.253906 63.550781 127.703125zm-72.566406-145.824218-15.636719-31.417969 1.167969-.578125 79.71875-39.671875 15.632813 31.414062zm42.125-118.136719 14.105469 28.347656-80.886719 40.253906-14.105469-28.351562zm-334.242187 63.800781h90.347656v193.824219h-90.347656zm0-51.90625h90.347656v31.667969h-90.347656zm0 265.972656h90.347656v34.398438h-90.347656zm110.589843 34.398438v-248.464844h22.285157v45.210938c0 12.996093 20.242187 13.015624 20.242187 0v-45.210938h22.289063v248.464844zm85.058594-174.804688h79.554687v174.804688h-79.554687zm191.53125 171.703125-13.476563-27.082031 80.886719-40.253906 13.472657 27.082031zm0 0"
        />
      )}
    </svg>
  )
}

export default Books

const AnimatedBooksIcon: React.FC<IAnimatedIcon> = ({
  offset,
  color,
  fromColor,
}) => {
  const { color: aColor, draw, fill } = useMemo(
    () => config(fromColor, color),
    []
  )

  const { fillColor, x }: any = useSpring({
    to: async (next: any) => {
      await next(draw)
      await next(fill)
    },
    from: {
      x: offset,
      fillColor: aColor,
    },
  })

  return (
    <>
      <animated.path
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
        d="m270.875 304.949219h-29.742188c-12.996093 0-13.015624 20.242187 0 20.242187h29.742188c12.992188 0 13.011719-20.242187 0-20.242187zm0 0"
      />
      <animated.path
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
        d="m402.871094 357.023438c1.546875 0 3.121094-.371094 4.507812-1.0625l99.007813-49.269532c5.003906-2.488281 7.039062-8.566406 4.550781-13.570312l-142.84375-287.035156c-2.457031-4.9375-8.628906-7.007813-13.570312-4.550782l-99.003907 49.269532c-5.003906 2.492187-7.042969 8.566406-4.550781 13.570312l23.121094 46.46875c.003906 0 .003906.003906.003906.003906l15.199219 30.539063h-73.402344v-63.535157c0-5.589843-4.53125-10.121093-10.121094-10.121093h-74.9375v-41.789063c0-5.589844-4.535156-10.121094-10.121093-10.121094h-110.589844c-5.589844 0-10.121094 4.53125-10.121094 10.121094v320.613282c0 5.589843 4.53125 10.121093 10.121094 10.121093zm-16.339844-20.242188h-70.84375v-142.355469zm-1.605469-48.652344-63.550781-127.703125 80.886719-40.253906 63.550781 127.703125zm-72.566406-145.824218-15.636719-31.417969 1.167969-.578125 79.71875-39.671875 15.632813 31.414062zm42.125-118.136719 14.105469 28.347656-80.886719 40.253906-14.105469-28.351562zm-334.242187 63.800781h90.347656v193.824219h-90.347656zm0-51.90625h90.347656v31.667969h-90.347656zm0 265.972656h90.347656v34.398438h-90.347656zm110.589843 34.398438v-248.464844h22.285157v45.210938c0 12.996093 20.242187 13.015624 20.242187 0v-45.210938h22.289063v248.464844zm85.058594-174.804688h79.554687v174.804688h-79.554687zm191.53125 171.703125-13.476563-27.082031 80.886719-40.253906 13.472657 27.082031zm0 0"
      />
      <animated.path
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
        d="m163.234375 183.867188c5.503906 0 10.121094-4.617188 10.121094-10.121094 0-5.5-4.621094-10.121094-10.121094-10.121094-5.34375 0-9.863281 4.351562-10.097656 9.679688-.253907 5.664062 4.421875 10.5625 10.097656 10.5625zm0 0"
      />
    </>
  )
}
