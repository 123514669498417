import { AnimatedSection } from "@components/shared"
import React from "react"
import {
  wrapper,
  wrapper__leaves,
  wrapper__info,
  wrapper__grid,
  circle,
} from "./style/blog.module.scss"

import { main__wrapper } from "../../../styles/typography.module.scss"
import LeavesSVG from "./icons/leaves.svg"
import BlogPost from "./BlogPost"

const Blog = (props) => {
  const { posts, language } = props
  let mainPosts = posts.filter((el) => el.categories.nodes[0].slug === "glowna")
  if (language === "en") {
    mainPosts = posts
      .filter((el) => el.categories.nodes[0].slug === "glowna-en")
      .reverse()
  }
  return (
    <>
      <div className={circle} />
      <div className={main__wrapper}>
        <AnimatedSection className={wrapper}>
          <h3>{translation[language].title}</h3>
          <p className={wrapper__info}>{translation[language].subtitle}</p>
          <div className={wrapper__leaves}>
            <img src={LeavesSVG} />
          </div>
          <div className={wrapper__grid}>
            {mainPosts.map((el, i: number) => (
              <BlogPost {...el} key={i} lang={language} />
            ))}
          </div>
        </AnimatedSection>
      </div>
    </>
  )
}

export default Blog

const translation = {
  pl: {
    title:
      "Zapraszamy także na bloga, aby dowiedzieć się więcej na temat konopii.",
    subtitle: "Najczęściej czytane artykuły",
  },
  en: {
    title: "Also visit the blog to learn more about cannabis.",
    subtitle: "Most popular articles",
  },
}
