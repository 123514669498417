import React, {
  useEffect,
  forwardRef,
  Ref,
  useCallback,
  useMemo,
  useState,
  useContext,
} from "react"
import { CartCtx } from "@components/contexted"
import {
  container,
  container__content,
  container__content__buttons,
  container__content__buttons__light,
  container__content__buttons__dark,
} from "./style/ebook.module.scss"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
import Books from "./icons/Animated/Books"

const getProps = () => {
  return {
    fromColor: "#FFF4DA",
    color: "#26262F",
  }
}
const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const Ebook: React.FC = (props, ref): JSX.Element => {
  const { language } = props
  const [state, setState] = useState({
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variation_id: null,
    variant: null,
    sale: false,
    price: "",
    regularPrice: "",
    unit: "PLN",
    name: "",
    slug: "",
    image: null,
  })
  const { products } = props
  const ebookProduct = products.find((el) => el.productId === 347)
  const { dispatch } = useContext(CartCtx)

  useEffect(() => {
    if (ebookProduct) {
      setState({
        product_id: ebookProduct.productId,
        name: ebookProduct.name,
        slug: ebookProduct.slug,
        price: cleanPrice(ebookProduct.regularPrice),
        regularPrice: cleanPrice(ebookProduct.regularPrice),
        regularValue: cleanPrice(ebookProduct.regularPrice),
        value: cleanPrice(ebookProduct.regularPrice),
        quantity: 1,
        image: ebookProduct.image,
      })
    }
  }, [ebookProduct])

  const clearAndAddNew = (product) => {
    dispatch({
      type: "resetCart",
    })
    dispatch({
      type: "addToCart",
      payload: product,
    })
  }
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <div className={container__content}>
          <h3>{translation[language].title}</h3>
          <p>{translation[language].subtitle}</p>
          <Books {...getProps()} />
          <div className={container__content__buttons}>
            <AnimatedButton className={container__content__buttons__light}>
              {translation[language].fragment}
            </AnimatedButton>
            <AnimatedButton
              className={container__content__buttons__dark}
              onClick={() => {
                clearAndAddNew(state), ref.current.toggle()
              }}
            >
              {translation[language].buy}
            </AnimatedButton>
          </div>
        </div>
      </section>
    </AnimatedSection>
  )
}

export default forwardRef(Ebook)

const translation = {
  pl: {
    title:
      "“40 korzyści wynikających ze stosowania przetworów konopii w życiu codziennym”",
    subtitle:
      "Jesli chciałbyć wiedzieć jak konopia może pomóc Ci w życiu zapraszamy do kupna ebooka",
    fragment: "Przeczytaj fragment",
    buy: "Kup ebook - 14 zł",
  },
  en: {
    title: "“40 benefits of using cannabis preparations in everyday life”",
    subtitle:
      "If you want to know how cannabis can help you in your life, you are welcome to buy the ebook",
    fragment: "Read an excerpt",
    buy: "Buy ebook - $14",
  },
}
