export default (color: string, toColor: string) => {
  return {
    draw: {
      x: 0,
      config: {
        duration: 800,
      },
    },
    fill: {
      fillColor: toColor,
      config: {
        duration: 400,
      },
    },
    color,
  }
}

export const getAnimatedSvgProps = (
  fillColor: string,
  strokeDashoffset: number,
  strokeDasharray: number,
  strokeColor: string
) => {
  return {
    stroke: strokeColor,
    strokeWidth: 0.5,
    strokeDashoffset,
    strokeDasharray,
    fill: fillColor,
  }
}

export interface IIcon {
  color: string
  fromColor: string
}

export interface IAnimatedIcon {
  offset: number
  color: string
  fromColor: string
}
