import { useActions, CustomerCtx, useContextState } from "@components/contexted"
import { AnimatedButton } from "@components/shared"
import React, { useState } from "react"
import Right from "./icons/Animated/right.svg"
import {
  content__modal__wrapper__inner__double,
  content__modal__wrapper__inner__double__single,
  content__modal__wrapper,
  content__modal__wrapper__title,
  content__modal__wrapper__title__text,
  content__modal__wrapper__title__open,
  content__modal__wrapper__title__close,
  content__modal__wrapper__inner,
  margin,
} from "./style/paymentmodal.module.scss"
const Where = () => {
  const [isAddress, setIsAddress] = useState(false)
  const { changeValue } = useActions(CustomerCtx, ["changeValue"])
  const { address_1, address_2, city, postcode } = useContextState(
    CustomerCtx,
    ["address_1", "address_2", "city", "postcode"]
  )

  return (
    <div className={content__modal__wrapper}>
      <div
        className={
          isAddress
            ? `${content__modal__wrapper__title} ${margin}`
            : content__modal__wrapper__title
        }
      >
        <div className={content__modal__wrapper__title__text}>
          Gdzie mamy wysłać paczkę?
        </div>
        <AnimatedButton
          className={
            isAddress
              ? content__modal__wrapper__title__open
              : content__modal__wrapper__title__close
          }
          onClick={() => setIsAddress((prev) => !prev)}
        >
          <img src={Right} alt="" />
        </AnimatedButton>
      </div>
      {isAddress && (
        <div className={content__modal__wrapper__inner}>
          {/* <label>Ulica</label>
          <input
            placeholder="np. Miejska"
            value={address_1}
            onChange={(ev) => {
              changeValue({ address_1: ev.target.value })
            }}
          /> */}
          <div className={content__modal__wrapper__inner__double}>
            <div className={content__modal__wrapper__inner__double__single}>
              <label htmlFor="street">Ulica</label>
              <input
                name="street"
                placeholder="np. Miejska 4"
                value={address_1}
                onChange={(ev) => {
                  changeValue({ address_1: ev.target.value })
                }}
              />
            </div>
            <div className={content__modal__wrapper__inner__double__single}>
              <label htmlFor="home-number">Numer mieszkania</label>
              <input
                name="home-number"
                placeholder="np. 3"
                value={address_2}
                onChange={(ev) => {
                  changeValue({ address_2: ev.target.value })
                }}
              />
            </div>
          </div>

          <div className={content__modal__wrapper__inner__double}>
            <div className={content__modal__wrapper__inner__double__single}>
              <label>Miasto </label>
              <input
                name="city"
                placeholder="np. Kraków"
                value={city}
                onChange={(ev) => {
                  changeValue({ city: ev.target.value })
                }}
              />
            </div>
            <div className={content__modal__wrapper__inner__double__single}>
              <label>Kod pocztowy</label>
              <input
                name="postal_code"
                placeholder={"np. 00-001"}
                value={postcode}
                onChange={(ev) => {
                  changeValue({ postcode: ev.target.value })
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Where
