import { AnimatedButton, Modal } from "@components/shared"
import React, { forwardRef, useState } from "react"
import Plane from "./icons/Animated/Plane"
import Leafs from "./icons/leaves.svg"
import { WPApi } from "@api"
import {
  content__modal,
  content__modal__button,
  content__modal__button__busy,
  content__modal__coupon,
  content__modal__coupon__text,
  content__modal__coupon__inner,
} from "./style/newslettermodal.module.scss"

const defaultState = {
  email: "",
  imBusy: false,
  msg: "",
}
const NewsletterModal = (props, ref): JSX.Element => {
  const [state, setState] = useState(defaultState)
  const { language } = props

  const signToNewsletter = () => {
    setState({ ...state, imBusy: true, msg: "" })
    WPApi.signToNewsletter(state.email).then((data) => {
      setState({
        email: "",
        imBusy: false,
        msg: data.message,
      })
    })
  }

  return (
    <Modal
      ref={ref}
      close={true}
      bgColor="rgba(0, 0, 0, 0.85)"
      noAnimation={true}
      title={false}
    >
      <form className={content__modal}>
        <h2>{translation[language].title}</h2>
        <div className={content__modal__coupon}>
          <div className={content__modal__coupon__text}>
            <p>{translation[language].subtitle}</p>
            <div className={content__modal__coupon__inner}>
              <label htmlFor="coupon">{translation[language].label}</label>
              <input
                name="email"
                placeholder={translation[language].email}
                value={state.email}
                onChange={(ev) =>
                  setState({ ...state, email: ev.target.value })
                }
              />
              <AnimatedButton
                className={
                  state.imBusy
                    ? content__modal__button__busy
                    : content__modal__button
                }
                onClick={() => signToNewsletter()}
              >
                <Plane />
                {translation[language].button}
              </AnimatedButton>
              {state.msg && <p>{state.msg}</p>}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default forwardRef(NewsletterModal)

const translation = {
  pl: {
    title: "Kolejna partia oleju właśnie jest w produkcji!",
    subtitle:
      "Zostaw nam swój adres email, a poinformujemy Cię o tym jak będzie gotowy i przebadany.",
    label: "Twój adres email",
    placeholder: "np. kowalski@gmail.com",
    button: "Wyślij",
  },
  en: {
    title: "Another shipment of oil is now in production!",
    subtitle:
      "Leave us your email address and we'll let you know when it's ready and tested.",
    label: "Your email address",
    placeholder: "e.g., johndoe@gmail.com",
    button: "Send",
  },
}
