import React, { useState } from "react"
import {
  container,
  container__content,
  container__content__form,
  container__content__form__email,
  container__content__form__btn,
  container__content__form__checkbox,
} from "./style/newsletter.module.scss"
import Plane from "./icons/plane.svg"
import { AnimatedButton, AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
import { INewsletterState } from "../types"
import { WPApi } from "@api"

const defaultState: INewsletterState = {
  email: "",
  msg: "",
  imBusy: false,
}

const Newsletter: React.FC = (props): JSX.Element => {
  const [checked, setChecked] = useState(false)
  const [state, setState] = useState(defaultState)
  const { language } = props
  const signToNewsletter = () => {
    setState({ ...state, imBusy: true, msg: "" })
    WPApi.signToNewsletter(state.email).then((data) => {
      setState({
        email: "",
        imBusy: false,
        msg: data.message,
      })
    })
  }
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <form className={container__content}>
          <p>{translation[language].title}</p>
          <div className={container__content__form}>
            <div>
              <label
                htmlFor="email"
                className={container__content__form__email}
              >
                {translation[language].email}
              </label>
              <input
                type="email"
                placeholder="np. jan.kowalski@gmail.com"
                value={state.email}
                onChange={(ev) =>
                  setState({ ...state, email: ev.target.value })
                }
              />
              <label
                htmlFor="agree"
                className={container__content__form__checkbox}
                onClick={() => setChecked((prev) => !prev)}
              >
                <input type="checkbox" checked={checked} />
                {translation[language].data}
              </label>
            </div>
            <AnimatedButton
              className={container__content__form__btn}
              onClick={() => signToNewsletter()}
            >
              {translation[language].button}
              <img src={Plane} alt="" />
            </AnimatedButton>
          </div>
        </form>
      </section>
    </AnimatedSection>
  )
}

export default Newsletter

const translation = {
  pl: {
    title:
      "Zapisz się do listy aby otrzymać fragment naszego e-booka i być na bieżąco z promocjami",
    email: "Podaj swój adres email",
    data: "* Zgadzam się na przetwarzanie moich danych",
    button: "Wyślij",
  },
  en: {
    title:
      "Subscribe to the list to receive an excerpt from our e-book and stay up to date on promotions",
    email: "Enter your email address",
    data: "* I consent to the processing of my data.",
    button: "Send",
  },
}
