import React, {
  useState,
  useContext,
  useEffect,
  forwardRef,
  useRef,
} from "react"
import { AnimatedButton, Modal } from "@components/shared"
import { Delivery } from "@components/ecommerce"
import {
  CustomerCtx,
  useActions,
  CartCtx,
  AppCtx,
  useContextState,
} from "@components/contexted"
import { createOrder, initPayment } from "../../ecommerce/Checkout/common"

import {
  content__modal,
  content__modal__buttons,
  content__modal__point,
  content__modal__buttons__btn,
  content__modal__buttons__btn__disabled,
  content__modal__checkbox,
  payment__class,
} from "./style/paymentmodal.module.scss"
import OrderForm from "./OrderForm"
import CardPay from "./icons/Animated/CardPay"
import Card from "./icons/Card.svg"

const PaymentModal = (props, ref): JSX.Element => {
  const [state, setState] = useState({ point: {} })
  const [payment, setPayment] = useState(false)
  const { dispatch } = useContext(CartCtx)
  const checkBoxRef = useRef<HTMLInputElement | null>(null)
  const checkBoxRefSecond = useRef<HTMLInputElement | null>(null)
  const cart = useContext(CartCtx)
  const { shipping } = cart.state
  const customer = useContext(CustomerCtx)
  const { loading, loaded } = useActions(AppCtx, ["loading", "loaded"])
  const { imBusy } = useContextState(AppCtx, "imBusy")
  const { setOrderFromApi, onOrderApiError } = useActions(CartCtx, [
    "setOrderFromApi",
    "onOrderApiError",
  ])

  if (typeof window !== "undefined") {
    window.easyPackAsyncInit = () => {
      easyPack.init({
        defaultLocale: "pl",
        mapType: "osm",
        searchType: "osm",
        points: {
          types: ["parcel_locker"],
          functions: ["parcel_collect"],
        },
        map: {
          initialTypes: ["parcel_locker"],
          useGeolocation: true,
        },
      })
    }
  }
  useEffect(() => {
    const parent = document.getElementById("uniq")
    const alreadyInDom = document.getElementById("easypack-map")

    if (window.easyPack) {
      if (shipping.method_title === "Paczkomat Inpost") {
        if (!alreadyInDom) {
          const mapContainer = document.createElement("div")
          mapContainer.setAttribute("id", "easypack-map")
          mapContainer.style.minHeight = "500px"
          parent?.appendChild(mapContainer)
          easyPack.mapWidget("easypack-map", (point) => {
            setState({
              point: { ...point },
            })
          })
        } else {
          alreadyInDom.remove()
        }
      }
    }

    return () => {
      document.getElementById("easypack-map")?.remove()
    }
  }, [shipping.method_title])

  useEffect(() => {
    if (shipping.method_title !== "Paczkomat Inpost") {
      setState({
        point: {},
      })
    }
  }, [shipping.method_title])

  const clearAndAddNew = (product) => {
    dispatch({
      type: "resetCart",
    })
    dispatch({
      type: "addToCart",
      payload: product,
    })
  }

  const resetError = () => {
    dispatch({
      type: "resetError",
    })
  }

  let disabled = false
  if (shipping.method_title === "Paczkomat Inpost" && !state.point.name) {
    disabled = true
  }

  return (
    <Modal
      ref={ref}
      close={true}
      bgColor="rgba(0, 0, 0, 0.85)"
      noAnimation={true}
      title={false}
      passedClass={payment__class}
    >
      <form className={content__modal}>
        <OrderForm />
        <Delivery />
        <label htmlFor="agree" className={content__modal__checkbox}>
          <input type="checkbox" required ref={checkBoxRefSecond} />
          Akceptacja regulaminu
        </label>
        <label htmlFor="agree" className={content__modal__checkbox}>
          <input type="checkbox" required ref={checkBoxRef} /> Zgoda na
          przetwarzanie danych
        </label>

        {state.point.name ? (
          <div className={content__modal__point}>
            <p>Paczkomat: {state.point.name}</p>
            <p>
              Adres:{" "}
              {state.point.address.line1 + " " + state.point.address.line2}
            </p>
          </div>
        ) : null}

        {shipping.method_title === "Paczkomat Inpost" ? (
          <div key="uniq" id="uniq"></div>
        ) : null}

        {cart.state.error && (
          <h4
            style={{ fontWeight: 700, textAlign: "center", marginTop: "10px" }}
          >
            {cart.state.error.message}
          </h4>
        )}
        <div className={content__modal__buttons}>
          <AnimatedButton
            className={
              disabled || imBusy
                ? content__modal__buttons__btn__disabled
                : content__modal__buttons__btn
            }
            onClick={() => {
              if (!cart.state.orderInApi.id) {
                resetError()
                if (
                  !checkBoxRef.current?.checked ||
                  !checkBoxRefSecond.current?.checked
                ) {
                  onOrderApiError({
                    message: "Zaakceptuj wymagane zgody",
                  })
                  return
                }
                createOrder({
                  cart: cart.state,
                  customer: customer.state,
                  initLoading: loading,
                  deliveryPoint: state.point,
                })
                  .then((data) => {
                    loaded()
                    setOrderFromApi(data)
                    setPayment(true)
                  })
                  .catch(({ data }) => {
                    loaded()
                    onOrderApiError(data)
                  })
              } else {
                initPayment({ cart, initLoading: loading })
                  .then((payment) => {
                    loaded()
                    window.location.href = payment.redirectUri
                  })
                  .catch((payment) => {
                    loaded()
                    console.log("err", payment)
                  })
              }
            }}
          >
            {cart.state.orderInApi.id ? (
              <>
                <img src={Card} alt="" />
                Rozpocznij płatność
              </>
            ) : (
              <>
                <CardPay />
                Potwierdź zamówienie
              </>
            )}
          </AnimatedButton>
        </div>
      </form>
    </Modal>
  )
}

export default forwardRef(PaymentModal)
