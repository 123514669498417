import React, { useRef, useEffect } from "react"
import { useActions, LangCtx, useContextState } from "@components/contexted"
import { Layout } from "@components/layout"
import {
  Hero,
  Order,
  Advantages,
  Blog,
  Check,
  Capacities,
  Opinions,
  Forest,
  Ebook,
  Warranty,
  About,
  Newsletter,
  PaymentModal,
  NewsletterModal,
} from "@components/standard"
import { isArray } from "util"

const IndexPage = ({ pageContext, location }) => {
  const { page, productsWithDetails, posts } = pageContext
  const ref = useRef()
  const paymentModalRef = useRef()
  const translationUrls = {
    pl: `/pl`,
    en: `/en`,
  }
  const { language } = useContextState(LangCtx, ["language"])
  return (
    <Layout {...page[0]} translationUrl={translationUrls}>
      <Hero language={language} />
      <Order products={productsWithDetails} ref={ref} language={language} />
      <Advantages language={language} />
      <Blog posts={posts} language={language} />
      <Check ref={ref} language={language} />
      <Capacities products={productsWithDetails} ref={ref} lang={language} />
      <Opinions language={language} />
      <Forest language={language} />
      <Ebook
        products={productsWithDetails}
        ref={paymentModalRef}
        language={language}
      />
      <Warranty language={language} />
      <Newsletter language={language} />
      <About language={language} />
      <NewsletterModal ref={ref} language={language} />
      <PaymentModal ref={paymentModalRef} />
    </Layout>
  )
}

export default IndexPage
