import React from "react"
import {
  images__wrapper,
  images__wrapper__image,
  images__wrapper__title,
  images__wrapper__leaves,
  images__wrapper__description,
  images__wrapper__content,
  images__wrapper__content__wrapper,
  images__wrapper__content__wrapper__icon,
  circle,
  reverse,
} from "./style/advantages.module.scss"
import { AnimatedSection } from "@components/shared"
import Yoga from "./images/people5.png"
import Winter from "./images/winter1.png"
import Sleep from "./images/sleep3.png"
import { main__wrapper } from "../../../styles/typography.module.scss"
import LeavesSVG from "./icons/leaves.svg"
import YogaIcon from "./icons/Animated/YogaIcon"
import SleepIcon from "./icons/Animated/SleepIcon"
import SneezeIcon from "./icons/Animated/Sneeze"

const getProps = () => {
  return {
    fromColor: "#FFFFFF",
    color: "#26262f",
  }
}

const getSmallProps = () => {
  return {
    fromColor: "#26262f",
    color: "#FFFFFF",
  }
}
const Advantages = (props) => {
  const { language } = props
  return (
    <div className={main__wrapper}>
      <div className={circle} />
      <section className={images__wrapper}>
        <h3 className={images__wrapper__title}>
          {translation[language].title}
        </h3>
        <p className={images__wrapper__description}>
          {translation[language].subtitle}
        </p>
        <div className={images__wrapper__leaves}>
          <img src={LeavesSVG} />
        </div>
        <div className={images__wrapper__content}>
          <div
            style={{ backgroundImage: `url(${Yoga})` }}
            className={images__wrapper__image}
          >
            <YogaIcon {...getSmallProps()} />
          </div>
          <div className={images__wrapper__content__wrapper}>
            <YogaIcon {...getProps()} />
            <h3>{translation[language].first_title}</h3>
            <p>{translation[language].first_subtitle}</p>
          </div>
        </div>
        <AnimatedSection className={`${images__wrapper__content} ${reverse}`}>
          <div className={images__wrapper__content__wrapper}>
            <SleepIcon {...getProps()} />
            <h3>{translation[language].second_title}</h3>
            <p>{translation[language].second_subtitle}</p>
          </div>
          <div
            style={{ backgroundImage: `url(${Sleep})` }}
            className={images__wrapper__image}
          >
            <SleepIcon {...getSmallProps()} />
          </div>
        </AnimatedSection>
        <AnimatedSection className={images__wrapper__content}>
          <div
            style={{ backgroundImage: `url(${Winter})` }}
            className={images__wrapper__image}
          >
            <SneezeIcon {...getSmallProps()} />
          </div>
          <div className={images__wrapper__content__wrapper}>
            <SneezeIcon {...getProps()} />
            <h3>{translation[language].third_title}</h3>
            <p>{translation[language].third_subtitle}</p>
          </div>
        </AnimatedSection>
      </section>
    </div>
  )
}

export default Advantages

const translation = {
  pl: {
    title: "Szukasz najlepszego, naturalnego i skutecznego preparatu CBD?",
    subtitle:
      "Zobacz jak regularne stosowanie konopii pomoże Ci w codziennym życiu.",
    first_title: "Zredukuj stres i zrelaksuj się",
    first_subtitle:
      "We współczesnym świecie szybkie tempo życia generuje nadmiar stresu związanego z obowiązkami rodzinnymi oraz pracą.",
    second_title: "Wyśpij się jak niemowlę",
    second_subtitle:
      "Nieregularny sen i bezsenność mogą prowadzić do przemęczenia i być przyczyną wielu problemów zdrowotnych.",
    third_title: "Podnieś swoją odporność i popraw regenerację",
    third_subtitle:
      "Obniżona odporność Twojego organizmu może prowadzić do częstych zachorowań w chłodniejszym okresie jesienno-zimowym.",
  },
  en: {
    title: "Looking for the best natural and effective CBD formula?",
    subtitle:
      "See how regular use of cannabis will help you in your daily life.",
    first_title: "Reduce stress and relax",
    first_subtitle:
      "In today's world, the fast pace of life generates an excess of stress related to family responsibilities and work.",
    second_title: "Sleep like a baby",
    second_subtitle:
      "Irregular sleep and insomnia can lead to fatigue and cause many health problems.",
    third_title: "Boost your immunity and improve your recovery",
    third_subtitle:
      "Your body's lowered immunity can lead to frequent illnesses during the colder fall and winter seasons.",
  },
}
