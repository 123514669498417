import {
  useActions,
  CustomerCtx,
  useContextState,
  CartCtx,
} from "@components/contexted"
import React, { useState } from "react"
import Invoice from "./Invoice"
import {
  content__modal__divider,
  content__modal__coupon,
  content__modal__coupon__text,
  content__modal__checkbox,
  content__modal__wrapper,
  content__modal__wrapper__title,
  content__modal__wrapper__title__text,
  content__modal__wrapper__title__open,
  content__modal__wrapper__title__close,
  content__modal__wrapper__inner,
  margin,
  content__modal__coupon__inner,
} from "./style/paymentmodal.module.scss"
import Divide1 from "./icons/leaves.svg"
import { AnimatedButton } from "@components/shared"
import Right from "./icons/Animated/right.svg"
import Where from "./Where"
const OrderForm: React.FC = (): JSX.Element => {
  const [isContact, setIsContact] = useState(false)

  const { changeValue } = useActions(CustomerCtx, ["changeValue"])
  const { setCoupon } = useActions(CartCtx, ["setCoupon"])
  const { first_name, last_name, email, phone } = useContextState(CustomerCtx, [
    "first_name",
    "last_name",
    "email",
    "phone",
  ])
  const { coupon } = useContextState(CartCtx, ["coupon"])

  return (
    <>
      <h3>Płatność</h3>
      <p>Jak możemy się z Tobą skontaktować</p>
      <img src={Divide1} alt="" className={content__modal__divider} />
      <div className={content__modal__coupon}>
        <div className={content__modal__coupon__text}>
          Czy posiadasz kod rabatowy?
          <div className={content__modal__coupon__inner}>
            <label htmlFor="coupon">Jeśli tak, to wpisz go poniżej</label>
            <input
              placeholder="np. 492231"
              onChange={(ev) => {
                setCoupon(ev.target.value)
              }}
              value={coupon}
            />
          </div>
        </div>
      </div>
      <div className={content__modal__wrapper}>
        <div
          className={
            isContact
              ? `${content__modal__wrapper__title} ${margin}`
              : content__modal__wrapper__title
          }
        >
          <div className={content__modal__wrapper__title__text}>
            Jak możemy się z Tobą skontaktować?
          </div>

          <AnimatedButton
            className={
              isContact
                ? content__modal__wrapper__title__open
                : content__modal__wrapper__title__close
            }
            onClick={() => setIsContact((prev) => !prev)}
          >
            <img src={Right} alt="" />
          </AnimatedButton>
        </div>
        {isContact && (
          <div className={content__modal__wrapper__inner}>
            <label htmlFor="name">Imię</label>
            <input
              placeholder="np. Jan "
              value={first_name}
              onChange={(ev) => {
                changeValue({ first_name: ev.target.value })
              }}
              name="name"
            />
            <label htmlFor="surname">Nazwisko</label>
            <input
              name="surname"
              placeholder="np. Kowalski "
              value={last_name}
              onChange={(ev) => {
                changeValue({ last_name: ev.target.value })
              }}
            />
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              name="email"
              placeholder="np. jan@kowalski.pl "
              value={email}
              onChange={(ev) => {
                changeValue({ email: ev.target.value })
              }}
            />
            <label htmlFor="number">Numer telefonu*</label>
            <input
              name="phone"
              placeholder="np. +48 123 456 789"
              value={phone}
              onChange={(ev) => {
                changeValue({ phone: ev.target.value })
              }}
            />
            <label htmlFor="agree" className={content__modal__checkbox}>
              <input type="checkbox" />
              Zgoda na dołączenie do newslettera
            </label>
          </div>
        )}
      </div>
      <Where />
      {/* <Invoice /> */}
    </>
  )
}

export default OrderForm
