import { useIntersectionObserver } from "@components/logic"
import React, { useMemo } from "react"
import { animated, useSpring } from "react-spring"
import useSVGOffset from "../../common/useSVGOffset"
import config, { getAnimatedSvgProps, IAnimatedIcon, IIcon } from "./config"

const ProtectionIcon: React.FC<IIcon> = ({ color, fromColor }) => {
  const { offset, pathRef } = useSVGOffset()
  const [{ inView }, marktoObserve] = useIntersectionObserver()
  return (
    <svg
      height="511pt"
      viewBox="-35 0 511 511.99925"
      width="511pt"
      xmlns="http://www.w3.org/2000/svg"
      ref={marktoObserve}
    >
      {typeof offset === "number" && inView ? (
        <AnimatedProtectionIcon
          offset={offset}
          color={color}
          fromColor={fromColor}
        />
      ) : (
        <path
          d="m441.519531 337.96875-10.707031-112.878906c-1.636719-17.253906-15.945312-30.269532-33.277344-30.269532-18.429687 0-33.425781 14.996094-33.425781 33.425782v90.214844c0 4.28125-1.4375 8.507812-4.042969 11.902343l-2.941406 3.828125c-1.9375-3.34375-4.511719-6.382812-7.699219-8.921875-6.8125-5.417969-15.320312-7.804687-23.957031-6.730469-8.636719 1.078126-16.296875 5.484376-21.566406 12.414063l-40.371094 53.054687c-10.578125 13.902344-16.40625 31.179688-16.40625 48.652344v69.339844c0 5.523438 4.480469 10 10 10 5.523438 0 10-4.476562 10-10v-69.34375c0-13.121094 4.375-26.097656 12.320312-36.539062l40.371094-53.054688c1.988282-2.609375 4.875-4.269531 8.128906-4.679688 3.253907-.402343 6.460938.496094 9.027344 2.539063 5.105469 4.0625 6.046875 11.5625 2.117188 16.757813l-16.941406 22.066406c-3.363282 4.382812-2.539063 10.660156 1.839843 14.023437 4.378907 3.363281 10.65625 2.539063 14.023438-1.839843l16.886719-21.996094c.03125-.039063.058593-.078125.089843-.117188l20.941407-27.269531c5.273437-6.871094 8.179687-15.421875 8.179687-24.085937v-90.214844c0-7.402344 6.023437-13.425782 13.425781-13.425782 6.960938 0 12.707032 5.226563 13.367188 12.160157l10.707031 112.875c.460937 4.890625-.914063 9.734375-3.839844 13.597656l-87.066406 113.371094c-1.339844 1.746093-2.066406 3.890625-2.066406 6.09375v29.082031c0 5.523438 4.476562 10 10 10 5.523437 0 10-4.476562 10-10v-25.6875l85.035156-110.730469c5.996094-7.917969 8.785156-17.726562 7.847656-27.613281zm0 0"
          ref={pathRef}
        />
      )}
    </svg>
  )
}

export default ProtectionIcon

const AnimatedProtectionIcon: React.FC<IAnimatedIcon> = ({
  offset,
  color,
  fromColor,
}) => {
  const { color: aColor, draw, fill } = useMemo(
    () => config(fromColor, color),
    []
  )

  const { fillColor, x }: any = useSpring({
    to: async (next: any) => {
      await next(draw)
      await next(fill)
    },
    from: {
      x: offset,
      fillColor: aColor,
    },
  })

  return (
    <>
      <animated.path
        d="m324.324219 251.894531c24.402343-34.839843 37.300781-75.738281 37.300781-118.273437v-94.835938c0-4.683594-3.253906-8.742187-7.824219-9.761718l-129.125-28.785157c-1.433593-.316406-2.917969-.316406-4.351562 0l-129.121094 28.785157c-4.574219 1.019531-7.824219 5.078124-7.824219 9.761718v94.835938c0 42.535156 12.898438 83.433594 37.300782 118.269531 24.402343 34.839844 58.429687 60.9375 98.40625 75.476563 1.101562.398437 2.261718.601562 3.414062.601562 1.15625 0 2.316406-.203125 3.417969-.601562 39.976562-14.535157 74.003906-40.636719 98.40625-75.472657zm-101.824219 55.394531c-34.617188-13.347656-64.105469-36.410156-85.4375-66.871093-22.035156-31.457031-33.683594-68.386719-33.683594-106.796875v-86.820313l119.121094-26.554687 119.125 26.554687v86.820313c0 38.40625-11.648438 75.335937-33.683594 106.796875-21.335937 30.460937-50.824218 53.523437-85.441406 66.871093zm0 0"
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
      />
      <animated.path
        d="m324.613281 68.453125c0-4.6875-3.25-8.742187-7.824219-9.761719l-92.109374-20.535156c-1.433594-.316406-2.921876-.316406-4.355469 0l-92.113281 20.535156c-5.390626 1.203125-8.785157 6.546875-7.582032 11.9375 1.199219 5.390625 6.546875 8.785156 11.933594 7.582032l89.9375-20.046876 82.113281 18.304688v57.152344c0 30.773437-9.332031 60.359375-26.984375 85.566406-14.183594 20.25-33.175781 36.65625-55.128906 47.683594-21.953125-11.023438-40.941406-27.433594-55.125-47.683594-15.269531-21.800781-24.441406-47.285156-26.519531-73.691406-.175781-2.203125-.300781-4.429688-.375-6.625-.191407-5.523438-4.851563-9.839844-10.339844-9.652344-5.519531.191406-9.839844 4.820312-9.648437 10.339844.085937 2.488281.226562 5.015625.421874 7.507812 2.359376 29.964844 12.761719 58.871094 30.078126 83.59375 17.105468 24.417969 40.378906 43.917969 67.308593 56.386719 1.332031.617187 2.769531.925781 4.203125.925781s2.867188-.308594 4.199219-.925781c26.933594-12.472656 50.207031-31.96875 67.308594-56.386719 20.019531-28.582031 30.605469-62.140625 30.605469-97.039062v-65.167969zm0 0"
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
      />
      <animated.path
        d="m194.011719 185.53125c1.75 2.070312 4.261719 3.339844 6.96875 3.519531.222656.015625.445312.023438.667969.023438 2.46875 0 4.863281-.914063 6.707031-2.582031l72.5-65.59375c4.09375-3.703126 4.410156-10.027344.707031-14.125-3.707031-4.09375-10.03125-4.410157-14.125-.707032l-64.832031 58.652344-18.503907-21.886719c-3.5625-4.214843-9.875-4.746093-14.089843-1.179687-4.21875 3.566406-4.746094 9.875-1.183594 14.09375zm0 0"
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
      />
      <animated.path
        d="m138.292969 330.953125c-5.269531-6.929687-12.929688-11.335937-21.566407-12.414063-8.636718-1.074218-17.144531 1.3125-23.957031 6.730469-3.1875 2.535157-5.761719 5.574219-7.703125 8.921875l-2.9375-3.828125c-2.609375-3.394531-4.042968-7.621093-4.042968-11.902343v-90.214844c0-18.429688-14.996094-33.425782-33.425782-33.425782-17.335937 0-31.640625 13.015626-33.277344 30.269532l-10.703124 112.878906c-.941407 9.886719 1.847656 19.695312 7.882812 27.664062l85 110.679688v25.6875c0 5.523438 4.476562 10 10 10 5.519531 0 10-4.476562 10-10v-29.085938c0-2.203124-.730469-4.34375-2.070312-6.089843l-87.027344-113.320313c-2.964844-3.914062-4.339844-8.761718-3.875-13.648437l10.707031-112.875c.65625-6.933594 6.402344-12.160157 13.363281-12.160157 7.40625 0 13.425782 6.023438 13.425782 13.425782v90.214844c0 8.664062 2.90625 17.214843 8.183593 24.082031l20.933594 27.261719c.03125.042968.0625.085937.09375.128906l16.890625 21.996094c3.367188 4.378906 9.644531 5.203124 14.023438 1.839843 4.378906-3.363281 5.203124-9.640625 1.839843-14.023437l-16.945312-22.066406c-3.925781-5.195313-2.988281-12.695313 2.121093-16.757813 2.566407-2.042969 5.773438-2.941406 9.027344-2.539063 3.253906.40625 6.140625 2.070313 8.125 4.679688l40.371094 53.054688c7.945312 10.441406 12.320312 23.417968 12.320312 36.539062v69.34375c0 5.523438 4.476563 10 10 10 5.523438 0 10-4.476562 10-10v-69.34375c0-17.46875-5.824218-34.746094-16.402343-48.648438zm0 0"
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
      />
      <animated.path
        d="m441.519531 337.96875-10.707031-112.878906c-1.636719-17.253906-15.945312-30.269532-33.277344-30.269532-18.429687 0-33.425781 14.996094-33.425781 33.425782v90.214844c0 4.28125-1.4375 8.507812-4.042969 11.902343l-2.941406 3.828125c-1.9375-3.34375-4.511719-6.382812-7.699219-8.921875-6.8125-5.417969-15.320312-7.804687-23.957031-6.730469-8.636719 1.078126-16.296875 5.484376-21.566406 12.414063l-40.371094 53.054687c-10.578125 13.902344-16.40625 31.179688-16.40625 48.652344v69.339844c0 5.523438 4.480469 10 10 10 5.523438 0 10-4.476562 10-10v-69.34375c0-13.121094 4.375-26.097656 12.320312-36.539062l40.371094-53.054688c1.988282-2.609375 4.875-4.269531 8.128906-4.679688 3.253907-.402343 6.460938.496094 9.027344 2.539063 5.105469 4.0625 6.046875 11.5625 2.117188 16.757813l-16.941406 22.066406c-3.363282 4.382812-2.539063 10.660156 1.839843 14.023437 4.378907 3.363281 10.65625 2.539063 14.023438-1.839843l16.886719-21.996094c.03125-.039063.058593-.078125.089843-.117188l20.941407-27.269531c5.273437-6.871094 8.179687-15.421875 8.179687-24.085937v-90.214844c0-7.402344 6.023437-13.425782 13.425781-13.425782 6.960938 0 12.707032 5.226563 13.367188 12.160157l10.707031 112.875c.460937 4.890625-.914063 9.734375-3.839844 13.597656l-87.066406 113.371094c-1.339844 1.746093-2.066406 3.890625-2.066406 6.09375v29.082031c0 5.523438 4.476562 10 10 10 5.523437 0 10-4.476562 10-10v-25.6875l85.035156-110.730469c5.996094-7.917969 8.785156-17.726562 7.847656-27.613281zm0 0"
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
      />
      <animated.path d="m124.835938 112.097656c3.519531 2.347656 8.285156 2.199219 11.636718-.382812 3.152344-2.429688 4.632813-6.671875 3.570313-10.523438-1.136719-4.128906-4.832031-7.160156-9.125-7.394531-4.25-.230469-8.261719 2.363281-9.820313 6.3125-1.710937 4.339844-.128906 9.386719 3.738282 11.988281zm0 0" />
    </>
  )
}
