import React from "react"

const Left = () => {
  return (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.031 0.280762L1.03099 11.2808L0.343994 11.9998L1.03099 12.7188L12.031 23.7188L13.469 22.2808L3.18699 11.9998L13.47 1.71876L12.031 0.280762Z"
        fill="black"
      />
    </svg>
  )
}

export default Left
