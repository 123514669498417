import React from "react"
import {
  hero,
  hero__leaf,
  hero__content,
  hero__content__button,
} from "./style/hero.module.scss"
import Bottle from "./images/bottle1.png"
import { AnimatedButton, AnimatedLink } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
import { navigate } from "gatsby"
const Hero: React.FC = (props): JSX.Element => {
  const { language } = props
  return (
    <div className={main__wrapper}>
      <section className={hero}>
        <div className={hero__content}>
          <h3>{translation[language].title}</h3>
          <p>{translation[language].subtitle}</p>
          <p>{translation[language].second_subtitle}</p>
          <AnimatedButton
            className={hero__content__button}
            onClick={async () => await navigate("/#check")}
          >
            {translation[language].button}
          </AnimatedButton>
        </div>
        <div className={hero__leaf}>
          <img alt="hero-leaf" src={Bottle} />
        </div>
      </section>
    </div>
  )
}

export default Hero

const translation = {
  pl: {
    title:
      "Każda kropla oleju The Old Hemp to bogactwo wszystkich cennych składników konopi.",
    subtitle:
      "Nasze oleje produkujemy korzystając z tradycyjnej i naturalnej metody.",
    second_subtitle: "Zobacz jak pomogą Ci nasze konopie:",
    button: "Kliknij, i przekonaj się!",
  },
  en: {
    title:
      "Every drop of The Old Hemp oil is rich in all the valuable ingredients of hemp",
    subtitle: "We produce our oils using a traditional and natural method.",
    second_subtitle: "See how our cannabis will help you:",
    button: "Click, and see for yourself!",
  },
}
