import React from "react"

const Right = () => {
  return (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.96903 0.280762L0.530029 1.71976L10.812 11.9998L0.532029 22.2808L1.96903 23.7188L12.969 12.7188L13.656 11.9998L12.969 11.2808L1.96903 0.280762Z"
        fill="black"
      />
    </svg>
  )
}

export default Right
