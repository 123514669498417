import React from "react"
import {
  wrapper__grid__item__content__block,
  wrapper__grid__item__content__block__date,
  wrapper__grid__item__content,
  wrapper__grid__item__content__button,
  wrapper__grid__item,
  wrapper__grid__item__tags,
  wrapper__grid__item__tags__tag,
} from "./style/blog.module.scss"
import BookSVG from "./icons/book.svg"
import Calendar from "../Home/icons/calendar.svg"
import dayjs from "dayjs"
import { withHtml } from "@components/logic"
import { AnimatedLink } from "@components/shared"
function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + "..."
}

const Content = withHtml("p")

const BlogPost = (props) => {
  const { title, content, tags, date, slug, featuredImage, lang } = props
  let clean = content.replace(/<\/?[^>]+(>|$)/g, "")
  return (
    <AnimatedLink
      className={wrapper__grid__item}
      to={`/${lang}/blog/${slug}`}
      style={{
        backgroundImage: `url(${featuredImage.node.sourceUrl})`,
      }}
    >
      <div>
        <h4>{title}</h4>
        <p>{truncateString(clean, 140)}</p>
      </div>
      <div className={wrapper__grid__item__tags}>
        {tags.nodes.map((tag, i) => (
          <AnimatedLink
            className={wrapper__grid__item__tags__tag}
            to={`/tags/${tag.slug}/`}
            key={i}
          >
            {"#" + tag.slug}
          </AnimatedLink>
        ))}
      </div>
      <div className={wrapper__grid__item__content}>
        <div className={wrapper__grid__item__content__block}>
          <div className={wrapper__grid__item__content__block__date}>
            <img src={Calendar} alt="" />
            {dayjs(date).format("DD-MM-YYYY")}
          </div>
        </div>
        <AnimatedLink
          to={`/${lang}/blog/${slug}`}
          className={wrapper__grid__item__content__button}
        >
          <img src={BookSVG} />
          {lang === "pl" ? "Przeczytaj" : "Read"}
        </AnimatedLink>
      </div>
    </AnimatedLink>
  )
}

export default BlogPost
