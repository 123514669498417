import { useIntersectionObserver } from "@components/logic"
import React, { useMemo } from "react"
import { animated, useSpring } from "react-spring"
import useSVGOffset from "../../common/useSVGOffset"
import config, { getAnimatedSvgProps, IAnimatedIcon, IIcon } from "./config"

const SleepIcon: React.FC<IIcon> = ({ color, fromColor }) => {
  const { offset, pathRef } = useSVGOffset()
  const [{ inView }, marktoObserve] = useIntersectionObserver()
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={marktoObserve}
    >
      {typeof offset === "number" && inView ? (
        <AnimatedSleepIcon
          offset={offset}
          color={color}
          fromColor={fromColor}
        />
      ) : (
        <path
          d="M9.91602 76.5907C10.8373 76.0298 11.1295 74.828 10.5686 73.9067C6.20996 66.7483 3.90625 58.4817 3.90625 50.0005C3.90625 28.6522 18.2783 11.1751 37.208 5.70614C33.56 12.0323 31.6406 19.2018 31.6406 26.563C31.6406 49.6099 50.3906 68.3599 73.4375 68.3599C80.7986 68.3599 87.9674 66.4409 94.2945 62.7925C88.8141 81.7606 71.3107 96.0942 50 96.0942C39.6316 96.0942 29.8598 92.7507 21.741 86.4253C20.8902 85.7624 19.6629 85.9145 19 86.7655C18.3369 87.6163 18.4895 88.8436 19.3402 89.5065C28.1516 96.3718 38.7535 100 50 100C73.9676 100 93.3699 83.3134 98.5854 61.8524L99.2768 59.0087C99.4654 58.2329 99.1629 57.4204 98.5125 56.9569C97.8623 56.4936 96.9955 56.4724 96.3236 56.904L93.8637 58.4833C87.7719 62.3892 80.7086 64.4536 73.4375 64.4536C52.5445 64.4536 35.5469 47.4559 35.5469 26.563C35.5469 19.2919 37.6113 12.2286 41.5164 6.13778L43.0965 3.67684C43.5279 3.00497 43.507 2.13817 43.0436 1.48798C42.5801 0.837587 41.7672 0.535438 40.9918 0.72372L38.1482 1.41513C16.7072 6.62567 0 26.0059 0 50.0005C0 59.1985 2.50078 68.1677 7.23203 75.9382C7.79297 76.8597 8.99453 77.1518 9.91602 76.5907Z"
          fill="#26262F"
          ref={pathRef}
        />
      )}
    </svg>
  )
}

export default SleepIcon

const AnimatedSleepIcon: React.FC<IAnimatedIcon> = ({
  offset,
  color,
  fromColor,
}) => {
  const { color: aColor, draw, fill } = useMemo(
    () => config(fromColor, color),
    []
  )

  const { fillColor, x }: any = useSpring({
    to: async (next: any) => {
      await next(draw)
      await next(fill)
    },
    from: {
      x: offset,
      fillColor: aColor,
    },
  })

  return (
    <>
      <animated.path
        d="M55.8594 34.375H63.9287L54.2344 48.9166C53.8348 49.5158 53.7975 50.2865 54.1373 50.9217C54.4772 51.5566 55.1391 51.9531 55.8594 51.9531H67.5781C68.6568 51.9531 69.5312 51.0787 69.5312 50C69.5312 48.9213 68.6568 48.0469 67.5781 48.0469H59.5088L69.2031 33.5053C69.6027 32.9061 69.64 32.1354 69.3002 31.5002C68.9603 30.8652 68.2984 30.4688 67.5781 30.4688H55.8594C54.7807 30.4688 53.9062 31.3432 53.9062 32.4219C53.9062 33.5006 54.7807 34.375 55.8594 34.375Z"
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
      />
      <animated.path
        d="M80.4688 3.90625H94.1406L78.9062 24.2188C78.4625 24.8105 78.391 25.6023 78.7219 26.2641C79.0527 26.9258 79.7289 27.3438 80.4688 27.3438H98.0469C99.1256 27.3438 100 26.4693 100 25.3906C100 24.3119 99.1256 23.4375 98.0469 23.4375H84.375L99.6094 3.125C100.053 2.5332 100.125 1.74141 99.7938 1.07969C99.4629 0.417969 98.7867 0 98.0469 0H80.4688C79.39 0 78.5156 0.874414 78.5156 1.95312C78.5156 3.03184 79.39 3.90625 80.4688 3.90625Z"
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
      />
      <animated.path
        d="M14.0625 83.9844C15.1412 83.9844 16.0156 83.1099 16.0156 82.0312C16.0156 80.9526 15.1412 80.0781 14.0625 80.0781C12.9838 80.0781 12.1094 80.9526 12.1094 82.0312C12.1094 83.1099 12.9838 83.9844 14.0625 83.9844Z"
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
      />
      <animated.path
        d="M9.91602 76.5907C10.8373 76.0298 11.1295 74.828 10.5686 73.9067C6.20996 66.7483 3.90625 58.4817 3.90625 50.0005C3.90625 28.6522 18.2783 11.1751 37.208 5.70614C33.56 12.0323 31.6406 19.2018 31.6406 26.563C31.6406 49.6099 50.3906 68.3599 73.4375 68.3599C80.7986 68.3599 87.9674 66.4409 94.2945 62.7925C88.8141 81.7606 71.3107 96.0942 50 96.0942C39.6316 96.0942 29.8598 92.7507 21.741 86.4253C20.8902 85.7624 19.6629 85.9145 19 86.7655C18.3369 87.6163 18.4895 88.8436 19.3402 89.5065C28.1516 96.3718 38.7535 100 50 100C73.9676 100 93.3699 83.3134 98.5854 61.8524L99.2768 59.0087C99.4654 58.2329 99.1629 57.4204 98.5125 56.9569C97.8623 56.4936 96.9955 56.4724 96.3236 56.904L93.8637 58.4833C87.7719 62.3892 80.7086 64.4536 73.4375 64.4536C52.5445 64.4536 35.5469 47.4559 35.5469 26.563C35.5469 19.2919 37.6113 12.2286 41.5164 6.13778L43.0965 3.67684C43.5279 3.00497 43.507 2.13817 43.0436 1.48798C42.5801 0.837587 41.7672 0.535438 40.9918 0.72372L38.1482 1.41513C16.7072 6.62567 0 26.0059 0 50.0005C0 59.1985 2.50078 68.1677 7.23203 75.9382C7.79297 76.8597 8.99453 77.1518 9.91602 76.5907Z"
        {...getAnimatedSvgProps(fillColor, x, offset, color)}
      />
    </>
  )
}
