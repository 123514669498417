import React from "react"
import {
  container,
  container__image,
  container__content,
  container__content__icons,
  container__content__icons__item,
} from "./style/warranty.module.scss"
import { AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
import Leaf from "./images/leaf.png"
import ProduktPolski from "./icons/Animated/ProduktPolski"
import Leafs from "./icons/Animated/Leafs"
import Earth from "./icons/Animated/Eath"

const getProps = () => {
  return {
    fromColor: "#FFFFFF",
    color: "#26262F",
  }
}
const Warranty: React.FC = (props): JSX.Element => {
  const { language } = props
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <div className={container__content}>
          <h3>{translation[language].title}</h3>
          <div className={container__content__icons}>
            <div className={container__content__icons__item}>
              <ProduktPolski {...getProps()} />
              <p>{translation[language].p1}</p>
            </div>
            <div className={container__content__icons__item}>
              <Leafs {...getProps()} />
              <p>{translation[language].p2}</p>
            </div>
            <div className={container__content__icons__item}>
              <Earth {...getProps()} />
              <p>{translation[language].p3}</p>
            </div>
          </div>
        </div>
        <img src={Leaf} alt="" className={container__image} />
      </section>
    </AnimatedSection>
  )
}

export default Warranty

const translation = {
  pl: {
    title: "3x 100% = pewność i gwarancja jakości",
    p1: "Produkt 100% Polski",
    p2: "Produkt 100% wegańkski",
    p3: "Produkt 100% naturalny",
  },
  en: {
    title: "3x 100% = confidence and guaranteed quality",
    p1: "Product 100% Polish",
    p2: "Product 100% vegan",
    p3: "Product 100% natural",
  },
}
