import React, { useState } from "react"
import {
  container,
  container__help__leaves,
  container__opinions,
  container__opinions__opinion,
  container__opinions__selected,
  arrow__left,
  arrow__right,
} from "./style/opinions.module.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Icon from "./images/placeholder.png"
import { main__wrapper } from "../../../styles/typography.module.scss"
import LeavesSVG from "./icons/leaves.svg"
import Slider from "react-slick"
import Left from "./icons/Animated/Left"
import Right from "./icons/Animated/Right"
import { AnimatedSection } from "@components/shared"

const OpinionsTable = [
  {
    name: "Sławek",
    icon: Icon,
    pl: {
      content:
        "Syn ma autyzm i ADHD. Terapeutka potwierdza skuteczność kropli. Syn potrafi skoncentrować się na zadaniach. Jestem zadowolony z zakupu. Poprzednie krople innej firmy nie przynosiły widocznych rezultatów. Te naprawdę wyciszają. Będziemy stosować regularnie. Polecam.",
    },
    en: {
      content:
        "Son has autism and ADHD. The therapist confirms the effectiveness of the drops. Son is able to concentrate on tasks. I am satisfied with the purchase. Previous drops from another company had no visible results. These really calm things down. We will use them regularly. I recommend.",
    },
  },
  {
    name: "Iwona i Rafał",
    icon: Icon,
    pl: {
      content:
        'Z uwagi na tempo życia i dużą ilość pracy czasem "wspomagamy swój relaks" i odprężamy się dzięki kilku kroplom oleju. Działają kojąco po ciężkim dniu w pracy za każdym razem.',
    },
    en: {
      content:
        'Due to the pace of life and a lot of work, we sometimes "aid our relaxation" and unwind with a few drops of oil. They have a soothing effect after a hard day at work every time.',
    },
  },
  {
    name: "Marcin",
    icon: Icon,
    pl: {
      content:
        "Od dziecka mam wybuchowy charakter. Krople pomagają mi być lepszą wersją siebie dla moich dzieci i powodują, że znacznie mniej się denerwuje.",
    },
    en: {
      content:
        "I have had an explosive personality since I was a kid. The drops help me be a better version of myself to my children and make me much less nervous.",
    },
  },
  {
    name: "Marek",
    icon: Icon,
    pl: {
      content:
        "Olej działa odprężająco, co po ciężkim dniu pracy poprawia samopoczucie. Dzięki kroplom nie mam już problemow z zasypianiem. Polecam.",
    },
    en: {
      content:
        "The oil has a relaxing effect, which makes me feel better after a hard day at work. Thanks to the drops I no longer have problems with falling asleep. I recommend.",
    },
  },
]

const Opinions: React.FC = (props): JSX.Element => {
  const { language } = props
  const [imageIndex, setImageIndex] = useState(0)
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    centerPadding: 0,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  }

  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <h3>{translation[language].title}</h3>
        <div className={container__help__leaves}>
          <img src={LeavesSVG}></img>
        </div>
        <Slider {...settings} className={container__opinions}>
          {OpinionsTable.map((el, i) => (
            <div
              className={
                i === imageIndex
                  ? container__opinions__selected
                  : container__opinions__opinion
              }
              key={i}
            >
              <img src={el.icon} alt="" />
              <h4>{el.name}</h4>
              <p>{el[language].content}</p>
            </div>
          ))}
        </Slider>
      </section>
    </AnimatedSection>
  )
}

export default Opinions

const LeftArrow = (props) => {
  const { onClick } = props
  return (
    <div onClick={onClick} className={arrow__left}>
      <Left />
    </div>
  )
}

const RightArrow = (props) => {
  const { onClick } = props
  return (
    <div onClick={onClick} className={arrow__right}>
      <Right />
    </div>
  )
}

const translation = {
  pl: {
    title: "Opinie naszych klientów",
  },
  en: {
    title: "Our customers' feedback",
  },
}
