import React from "react"
import {
  container,
  container__wrapper,
  container__forest,
  container__wrapper__content,
} from "./style/forest.module.scss"
import ForestImage from "./images/forest.png"
import { AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
import Logo from "./icons/Animated/Logo"
const Forest: React.FC = (props): JSX.Element => {
  const { language } = props
  return (
    <AnimatedSection className={main__wrapper}>
      <section className={container}>
        <div
          style={{ backgroundImage: `url(${ForestImage})` }}
          className={container__forest}
        >
          <Logo />
        </div>
        <div className={container__wrapper}>
          <div className={container__wrapper__content}>
            <Logo />
            <h3>{translation[language].title}</h3>
            <p>{translation[language].subtitle}</p>
            <p>{translation[language].p}</p>
          </div>
        </div>
      </section>
    </AnimatedSection>
  )
}

export default Forest

const translation = {
  pl: {
    title: "Wyznajemy holistyczne podejście do naturalnej mocy konopi",
    subtitle:
      "Nasze oleje są bliskie naturalnemu składowi rosliny. Dbamy, abynasz olej CBD zawierał całą gamę dobroczynnych substacji w niezmienionych proporcjach, który wytworzyła Matka Natura",
    p: "Abyś mógł cieszyć się zdrowiem jak najdłużej.",
  },
  en: {
    title: "We profess a holistic approach to the natural power of cannabis",
    subtitle:
      "Our oils are close to the natural composition of the plant. We make sure that our CBD oil contains the whole range of beneficial substances in unchanged proportions, which was created by Mother Nature",
    p: "So that you can enjoy your health for as long as possible.",
  },
}
