import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  forwardRef,
} from "react"
import {
  CustomerCtx,
  useActions,
  CartCtx,
  AppCtx,
  useContextState,
} from "@components/contexted"

import {
  order__container,
  order__container__content,
  order__small__pay,
  order__small__modal,
  order__small__modal__content,
  order__container__content__select,
  order__container__content__capacity,
  order__container__content__quantity,
  order__container__content__pay,
  order__container__content__quantity__buttons,
  order__container__content__quantity__buttons__btn,
  order__container__content__quantity__buttons__block,
  order__container__content__pay__sum,
  order__container__content__pay__sum__btn,
} from "./style/order.module.scss"
import { AnimatedButton } from "@components/shared"
import useClickOutside from "./common/useClickOutside"
import PaymentModal from "./PaymentModal"
import Left from "./icons/Animated/Left"
import Right from "./icons/Animated/Right"
import Cart from "./icons/cart_pay.svg"
const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}
const Order = (props, ref): JSX.Element => {
  const { dispatch } = useContext(CartCtx)
  const [state, setState] = useState({
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variation_id: null,
    variant: null,
    sale: false,
    price: "",
    regularPrice: "",
    unit: "PLN",
    name: "",
    slug: "",
    image: null,
  })
  const { products, language } = props
  const onePersonProduct = products.find(
    (el) => el.name === "Pakiet dla 1 osoby 15ml"
  )
  const twoPersonsProduct = products.find(
    (el) => el.name === "Pakiet dla pary 30ml"
  )
  const familyProduct = products.find(
    (el) => el.name === "Pakiet dla całej rodziny 50ml"
  )

  const clearAndAddNew = (product) => {
    dispatch({
      type: "resetCart",
    })
    dispatch({
      type: "addToCart",
      payload: product,
    })
  }

  const changeQty = (val) => {
    const _number = Number(val)
    if (_number <= 0 || Number.isNaN(_number)) {
      return false
    } else {
      setState({
        ...state,
        quantity: _number,
        value: _number * state.price,
        regularValue: _number * state.regularPrice,
      })
    }
  }

  const addToCart = (product) => {
    setState({
      product_id: product.productId,
      name: product.name,
      slug: product.slug,
      price: cleanPrice(product.regularPrice),
      regularPrice: cleanPrice(product.regularPrice),
      regularValue: cleanPrice(product.regularPrice),
      value: cleanPrice(product.regularPrice),
      quantity: 1,
      image: product.image,
    })
  }

  useEffect(() => {
    addToCart(onePersonProduct)
  }, [])

  return (
    <section className={order__container}>
      <div className={order__container__content}>
        <p>{translation[language].first_paragraph}</p>
        <div>
          <label
            htmlFor="capacity"
            className={order__container__content__capacity}
          >
            {translation[language].select_capacity}
          </label>
          <div className={order__container__content__select}>
            <select
              name="capacity"
              onChange={(ev) => addToCart(JSON.parse(ev.target.value))}
            >
              <option value={JSON.stringify(onePersonProduct)}>15ml</option>
              <option value={JSON.stringify(twoPersonsProduct)}>30ml</option>
              <option value={JSON.stringify(familyProduct)}>50ml</option>
            </select>
          </div>
        </div>
        <div className={order__container__content__quantity}>
          <label
            htmlFor="quantity"
            className={order__container__content__capacity}
            style={{ textAlign: "center" }}
          >
            {translation[language].quantity}
          </label>
          <div className={order__container__content__quantity__buttons}>
            <AnimatedButton
              className={order__container__content__quantity__buttons__btn}
              onClick={() => {
                if (state.quantity <= 1) {
                  return false
                } else {
                  changeQty(state.quantity - 1)
                }
              }}
            >
              <Left />
            </AnimatedButton>

            <div
              className={order__container__content__quantity__buttons__block}
            >
              {state.quantity}
            </div>
            <AnimatedButton
              className={order__container__content__quantity__buttons__btn}
              onClick={() => changeQty(state.quantity + 1)}
            >
              <Right />
            </AnimatedButton>
          </div>
        </div>
        <div className={order__container__content__pay}>
          <label
            htmlFor="quantity"
            className={order__container__content__capacity}
          >
            {translation[language].to_payment}
          </label>
          <div className={order__container__content__pay__sum}>
            <p>
              Suma: <b>{state.value} zł</b>
            </p>
            <AnimatedButton
              className={order__container__content__pay__sum__btn}
              onClick={() => {
                clearAndAddNew(state), ref.current.toggle()
              }}
            >
              <img src={Cart} />
              {translation[language].pay}
            </AnimatedButton>
          </div>
        </div>
      </div>
    </section>
  )
}

export default forwardRef(Order)

const translation = {
  pl: {
    first_paragraph: "Znasz już nasze produkty? Skorzystaj z szybkiego zakupu",
    select_capacity: "Wybierz pojemność",
    quantity: "Podaj ilość",
    to_payment: "Przejdź do zapłaty",
    pay: "Zapłać",
  },
  en: {
    first_paragraph:
      "Already familiar with our products? Take advantage of the quick purchase",
    select_capacity: "Select capacity",
    quantity: "Specify quantity",
    to_payment: "Go to payment",
    pay: "Pay",
  },
}
