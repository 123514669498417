import React from "react"

const Plane = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0696 0.996043C23.7486 1.0194 23.4335 1.09471 23.1366 1.21904H23.1326C22.8476 1.33204 21.4926 1.90204 19.4326 2.76604L12.0506 5.87504C6.75363 8.10504 1.54663 10.301 1.54663 10.301L1.60863 10.277C1.60863 10.277 1.24963 10.395 0.87463 10.652C0.642958 10.7995 0.443604 10.9924 0.28863 11.219C0.10463 11.489 -0.0433696 11.902 0.0116304 12.329C0.10163 13.051 0.56963 13.484 0.90563 13.723C1.24563 13.965 1.56963 14.078 1.56963 14.078H1.57763L6.46063 15.723C6.67963 16.426 7.94863 20.598 8.25363 21.559C8.43363 22.133 8.60863 22.492 8.82763 22.766C8.93363 22.906 9.05763 23.023 9.20663 23.117C9.28409 23.1621 9.36668 23.1977 9.45263 23.223L9.40263 23.211C9.41763 23.215 9.42963 23.227 9.44063 23.231C9.48063 23.242 9.50763 23.246 9.55863 23.254C10.3316 23.488 10.9526 23.008 10.9526 23.008L10.9876 22.98L13.8706 20.355L18.7026 24.062L18.8126 24.109C19.8196 24.551 20.8396 24.305 21.3786 23.871C21.9216 23.434 22.1326 22.875 22.1326 22.875L22.1676 22.785L25.9016 3.65604C26.0076 3.18404 26.0346 2.74204 25.9176 2.31304C25.797 1.87887 25.5184 1.50545 25.1366 1.26604C24.8161 1.07116 24.4443 0.977092 24.0696 0.996043ZM23.9686 3.04604C23.9646 3.10904 23.9766 3.10204 23.9486 3.22304V3.23404L20.2496 22.164C20.2336 22.191 20.2066 22.25 20.1326 22.309C20.0546 22.371 19.9926 22.41 19.6676 22.281L13.7576 17.75L10.1876 21.004L10.9376 16.214L20.5936 7.21404C20.9916 6.84404 20.8586 6.76604 20.8586 6.76604C20.8866 6.31204 20.2576 6.63304 20.2576 6.63304L8.08163 14.176L8.07763 14.156L2.24163 12.191V12.187L2.22663 12.184C2.23686 12.1806 2.24688 12.1766 2.25663 12.172L2.28863 12.156L2.31963 12.145C2.31963 12.145 7.53063 9.94904 12.8276 7.71904C15.4796 6.60204 18.1516 5.47704 20.2066 4.60904C22.2616 3.74604 23.7806 3.11304 23.8666 3.07904C23.9486 3.04704 23.9096 3.04704 23.9686 3.04704V3.04604Z"
        fill="white"
      />
    </svg>
  )
}

export default Plane
