import React from "react"

interface Props {}

const CardPay: React.FC<Props> = (): JSX.Element => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 0.750031C0.3375 0.750031 0 1.08753 0 1.50003C0 1.91253 0.3375 2.25003 0.75 2.25003H2.41425L4.383 10.125C4.5495 10.7925 5.148 11.25 5.83575 11.25H14.4375C15.114 11.25 15.6885 10.8015 15.867 10.1483L17.8125 3.00003H5.25L5.625 4.50003H15.867L14.4375 9.75003H5.83575L3.867 1.87503C3.78589 1.55201 3.5987 1.26557 3.33543 1.06159C3.07216 0.857607 2.74804 0.747896 2.415 0.750031H0.75ZM13.5 11.25C12.2662 11.25 11.25 12.2663 11.25 13.5C11.25 14.7338 12.2662 15.75 13.5 15.75C14.7338 15.75 15.75 14.7338 15.75 13.5C15.75 12.2663 14.7338 11.25 13.5 11.25ZM6.75 11.25C5.51625 11.25 4.5 12.2663 4.5 13.5C4.5 14.7338 5.51625 15.75 6.75 15.75C7.98375 15.75 9 14.7338 9 13.5C9 12.2663 7.98375 11.25 6.75 11.25ZM6.75 12.75C7.17225 12.75 7.5 13.0785 7.5 13.5C7.5 13.9223 7.1715 14.25 6.75 14.25C6.32775 14.25 6 13.9215 6 13.5C6 13.0778 6.3285 12.75 6.75 12.75ZM13.5 12.75C13.9222 12.75 14.25 13.0785 14.25 13.5C14.25 13.9223 13.9215 14.25 13.5 14.25C13.0778 14.25 12.75 13.9215 12.75 13.5C12.75 13.0778 13.0785 12.75 13.5 12.75Z"
        fill="#0E3019"
      />
    </svg>
  )
}

export default CardPay
