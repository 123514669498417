import React from "react"
import {
  container,
  container__description,
  circle,
} from "./style/about.module.scss"
import { AnimatedSection } from "@components/shared"
import { main__wrapper } from "../../../styles/typography.module.scss"
import Decoration from "./icons/decoration.svg"
import Logo from "./icons/logo.svg"
const About: React.FC = (props): JSX.Element => {
  const { language } = props
  return (
    <>
      <div className={circle} />
      <AnimatedSection className={container}>
        <img src={Logo} alt="" />
        <div className={container__description}>
          <h3>{translation[language].title}</h3>
          <img src={Decoration} alt="" />
          <p>{translation[language].p}</p>
        </div>
      </AnimatedSection>
    </>
  )
}

export default About

const translation = {
  pl: {
    title: "Krótko o nas",
    p:
      "W The Old Hemp dbamy o wiedzę. Dzielimy się z tobą aktualnymi informacjami i badaniami na temat konopi. W oparciu o nie, możesz świadomie i bezpiecznie podjąć decyzję o stosowaniu olejków CBD w określonych sytuacjach. W sklepie oferujemy tylko nasze olejki premium CBD - pełne spektrum. Aktualne badania potwierdzają dobroczynny wpływ wielu substancji konopnych na organizm ludzki. Nieustannie pilnujemy jakości naszych olejów na każdym etapie: produkcji, magazynowania i dystrybucji. Mając wszystko pod kontrolą dostarczamy zawsze świeżą partię produktu najwyższej jakości.",
  },
  en: {
    title: "About us",
    p:
      "At The Old Hemp, we care about knowledge. We share with you the latest information and research about cannabis. Based on these, you can make an informed and safe decision about the use of CBD oils in specific situations. In the store, we only offer our premium CBD - full spectrum. Current research confirms beneficial effects of many hemp substances on the human body. We constantly watch over the quality of our oils at every stage: production, storage and distribution. With everything under control we always deliver a fresh batch of the highest quality product.",
  },
}
